import * as React from "react";
import Layout from "../comps/Layout";
import Hero from "../comps/Hero";
import Section from "../comps/Section";
import Stack from "../comps/Stack";
import SectionHeading from "../comps/SectionHeading";
import ContactItem from "../comps/ContactItem";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <title>Kontakt - Astea</title>

      <Hero text="Kontakt" bg="/kontakt.png" />
      <Section>
        <SectionHeading>Kontakta oss</SectionHeading>
        <Stack gap={96}>
          <div>
            <iframe
              style={{
                border: 0,
                borderRadius: "16px",
                boxShadow: "rgba(0,0,0,.1) 0 0 0 1px",
                width: "100%",
              }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.9414175098254!2d18.074765216255397!3d59.33393188166052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d5baea05ed5%3A0x9e0e88b1dd597d49!2sNybrogatan%206%2C%20137%2C%20114%2034%20Stockholm!5e0!3m2!1sen!2sse!4v1677423567450!5m2!1sen!2sse"
              maxWidth="430px"
              height="430px"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <Stack v vcenter mobile>
            <ContactItem
              icon={
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 5C16.0523 5 16.5 5.44772 16.5 6V6.01C16.5 6.56228 16.0523 7.01 15.5 7.01C14.9477 7.01 14.5 6.56228 14.5 6.01V6C14.5 5.44772 14.9477 5 15.5 5Z"
                    fill="#031C08"
                  />
                  <path
                    d="M20.5 6C20.5 5.44772 20.0523 5 19.5 5C18.9477 5 18.5 5.44772 18.5 6V6.01C18.5 6.56228 18.9477 7.01 19.5 7.01C20.0523 7.01 20.5 6.56228 20.5 6.01V6Z"
                    fill="#031C08"
                  />
                  <path
                    d="M15.5 8.9902C16.0523 8.9902 16.5 9.43791 16.5 9.9902V10.0002C16.5 10.5525 16.0523 11.0002 15.5 11.0002C14.9477 11.0002 14.5 10.5525 14.5 10.0002V9.9902C14.5 9.43791 14.9477 8.9902 15.5 8.9902Z"
                    fill="#031C08"
                  />
                  <path
                    d="M20.5 9.9902C20.5 9.43791 20.0523 8.9902 19.5 8.9902C18.9477 8.9902 18.5 9.43791 18.5 9.9902V10.0002C18.5 10.5525 18.9477 11.0002 19.5 11.0002C20.0523 11.0002 20.5 10.5525 20.5 10.0002V9.9902Z"
                    fill="#031C08"
                  />
                  <path
                    d="M15.5 12.9902C16.0523 12.9902 16.5 13.4379 16.5 13.9902V14.0002C16.5 14.5525 16.0523 15.0002 15.5 15.0002C14.9477 15.0002 14.5 14.5525 14.5 14.0002V13.9902C14.5 13.4379 14.9477 12.9902 15.5 12.9902Z"
                    fill="#031C08"
                  />
                  <path
                    d="M20.5 13.9902C20.5 13.4379 20.0523 12.9902 19.5 12.9902C18.9477 12.9902 18.5 13.4379 18.5 13.9902V14.0002C18.5 14.5525 18.9477 15.0002 19.5 15.0002C20.0523 15.0002 20.5 14.5525 20.5 14.0002V13.9902Z"
                    fill="#031C08"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.5 0C10.9477 0 10.5 0.447715 10.5 1V23C10.5 23.5523 10.9477 24 11.5 24H23.5C24.0523 24 24.5 23.5523 24.5 23V1C24.5 0.447715 24.0523 0 23.5 0H11.5ZM12.5 22V2H22.5V22H12.5Z"
                    fill="#031C08"
                  />
                  <path
                    d="M0.5 8C0.5 7.44772 0.947715 7 1.5 7H7.5C8.05228 7 8.5 7.44772 8.5 8C8.5 8.55228 8.05228 9 7.5 9H2.5V22H7.5C8.05228 22 8.5 22.4477 8.5 23C8.5 23.5523 8.05228 24 7.5 24H1.5C0.947715 24 0.5 23.5523 0.5 23V8Z"
                    fill="#031C08"
                  />
                  <path
                    d="M6.5 12.9902C6.5 12.4379 6.05228 11.9902 5.5 11.9902C4.94772 11.9902 4.5 12.4379 4.5 12.9902V13.0002C4.5 13.5525 4.94772 14.0002 5.5 14.0002C6.05228 14.0002 6.5 13.5525 6.5 13.0002V12.9902Z"
                    fill="#031C08"
                  />
                  <path
                    d="M6.5 16.9902C6.5 16.4379 6.05228 15.9902 5.5 15.9902C4.94772 15.9902 4.5 16.4379 4.5 16.9902V17.0002C4.5 17.5525 4.94772 18.0002 5.5 18.0002C6.05228 18.0002 6.5 17.5525 6.5 17.0002V16.9902Z"
                    fill="#031C08"
                  />
                </svg>
              }
            >
              Nybrogatan 6, 114 34 Stockholm
            </ContactItem>
            <ContactItem
              icon={
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5002 10.5555C11.15 10.5555 10.0558 11.6498 10.0558 13C10.0558 14.3502 11.15 15.4444 12.5002 15.4444C13.8504 15.4444 14.9447 14.3502 14.9447 13C14.9447 11.6498 13.8504 10.5555 12.5002 10.5555ZM7.61133 13C7.61133 10.2997 9.79998 8.11108 12.5002 8.11108C15.2005 8.11108 17.3891 10.2997 17.3891 13C17.3891 15.7002 15.2005 17.8889 12.5002 17.8889C9.79998 17.8889 7.61133 15.7002 7.61133 13Z"
                    fill="#031C08"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.27832 13.0001C0.27832 6.24937 5.74986 0.777832 12.5005 0.777832C19.2512 0.777832 24.7228 6.24937 24.7228 13.0001C24.7228 15.7003 22.5341 17.8889 19.8339 17.8889C17.1336 17.8889 14.945 15.7003 14.945 13.0001C14.945 12.325 15.4922 11.7778 16.1672 11.7778C16.8422 11.7778 17.3894 12.325 17.3894 13.0001C17.3894 14.3503 18.4837 15.4445 19.8339 15.4445C21.1841 15.4445 22.2783 14.3503 22.2783 13.0001C22.2783 7.5994 17.9012 3.22228 12.5005 3.22228C7.09989 3.22228 2.72276 7.5994 2.72276 13.0001C2.72276 18.4007 7.09989 22.7778 12.5005 22.7778C13.1756 22.7778 13.7228 23.325 13.7228 24.0001C13.7228 24.6751 13.1756 25.2223 12.5005 25.2223C5.74986 25.2223 0.27832 19.7507 0.27832 13.0001Z"
                    fill="#031C08"
                  />
                </svg>
              }
            >
              kontakt@astea.se
            </ContactItem>
            <ContactItem
              icon={
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.47323 2.54785L4.14239 0.87868C5.31396 -0.292893 7.21346 -0.292894 8.38503 0.878679L11.2135 3.70711C12.385 4.87868 12.385 6.77817 11.2135 7.94975L9.23065 9.93255C10.2343 12.2873 12.1494 14.1583 14.5201 15.2497L16.5168 13.253C17.6883 12.0815 19.5878 12.0815 20.7594 13.253L23.5878 16.0815C24.7594 17.253 24.7594 19.1525 23.5878 20.3241L22.0198 21.8922C20.3318 23.5801 17.889 24.2715 15.5668 23.7186L15.2939 23.6536C7.96048 21.9076 2.27309 16.1189 0.656787 8.75571C0.16428 6.51207 0.848958 4.17212 2.47323 2.54785ZM5.55661 2.29289L3.88744 3.96206C2.74539 5.10411 2.26399 6.74936 2.61028 8.3269C4.06202 14.9404 9.17036 20.1397 15.7572 21.708L16.0301 21.773C17.6767 22.1651 19.4087 21.6748 20.6056 20.478L22.1736 18.9099C22.5641 18.5194 22.5641 17.8862 22.1736 17.4957L19.3452 14.6673C18.9547 14.2767 18.3215 14.2767 17.931 14.6673L15.4561 17.1421C15.1716 17.4266 14.7443 17.5129 14.3717 17.3611L14.2322 17.3043C11.0325 16.0007 8.39856 13.4847 7.18183 10.1911C7.01076 9.72803 7.12574 9.20904 7.4734 8.86138L9.79925 6.53553C10.1898 6.14501 10.1898 5.51184 9.79925 5.12132L6.97082 2.29289C6.5803 1.90237 5.94713 1.90237 5.55661 2.29289Z"
                    fill="#031C08"
                  />
                </svg>
              }
            >
              08-546 01 749
            </ContactItem>
            <ContactItem
              icon={
                <svg
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.592305 6.50374C0.823771 6.0023 1.41791 5.78345 1.91935 6.01491L11.2425 10.3185C11.2424 10.3185 11.2427 10.3186 11.2425 10.3185C12.0409 10.686 12.9592 10.6862 13.7575 10.3187C13.7574 10.3188 13.7577 10.3187 13.7575 10.3187L23.0811 6.01491C23.5826 5.78345 24.1767 6.0023 24.4082 6.50374C24.6396 7.00518 24.4208 7.59932 23.9193 7.83079L14.5953 12.1348C13.2659 12.747 11.7354 12.7474 10.4059 12.1352L1.08113 7.83079C0.579694 7.59932 0.360838 7.00518 0.592305 6.50374Z"
                    fill="#031C08"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.5 3C2.94828 3 2.5 3.44828 2.5 4V18C2.5 18.5517 2.94828 19 3.5 19H21.5C22.0517 19 22.5 18.5517 22.5 18V4C22.5 3.44828 22.0517 3 21.5 3H3.5ZM0.5 4C0.5 2.34372 1.84372 1 3.5 1H21.5C23.1563 1 24.5 2.34372 24.5 4V18C24.5 19.6563 23.1563 21 21.5 21H3.5C1.84372 21 0.5 19.6563 0.5 18V4Z"
                    fill="#031C08"
                  />
                </svg>
              }
            >
              Nybrogatan 6, 102 40, Box 5855
            </ContactItem>
            <ContactItem
              icon={
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 8.65228H12.5V5.78271"
                    stroke="#031C08"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.5 8.6521V22.0434"
                    stroke="#031C08"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.5 8.6521V22.0434"
                    stroke="#031C08"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.5 22.0435H23.5"
                    stroke="#031C08"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.5 8.65217C20.5 12.8781 16.918 16.3043 12.5 16.3043C8.082 16.3043 4.5 12.8781 4.5 8.65217C4.5 4.42626 8.082 1 12.5 1C16.918 1 20.5 4.42626 20.5 8.65217Z"
                    stroke="#031C08"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            >
              09.00 - 17.00, mån - fre
            </ContactItem>
          </Stack>
        </Stack>
      </Section>
    </Layout>
  );
};

export default IndexPage;
