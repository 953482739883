import React from "react";
const ContactItem = ({ children, icon }) => {
  return (
    <div className="contact-item">
      {icon}
      <span>{children}</span>
    </div>
  );
};

export default ContactItem;
